.preloader{
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 55;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.preloader .gif-container img {
    width: 100%; 
    height: 100vh;
  }